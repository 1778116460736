import IRoute from "./IRoute";
import { Home, Legacy, Contact, WhatMessenger } from "views";

const routes: IRoute[] = [
  {
    path: "/legacy",
    Component: Legacy,
  },
  {
    path: "/contact",
    Component: Contact,
  },
  {
    path: "/whatMessenger",
    Component: WhatMessenger,
  },
  {
    path: "/",
    Component: Home,
  },
];
export default routes;
