import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Router } from "route";
import { Layout } from "views";
import "antd/dist/antd.css";
import "index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout router={Router} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
