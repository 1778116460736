import { Card } from "antd";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import whatMessengerDoc from "./WhatMessenger.md";

const StyledCard = styled(Card)`
  background: none;
`;

const WhatMessenger = () => {
  return (
    <StyledCard>
      <ReactMarkdown>{whatMessengerDoc}</ReactMarkdown>
    </StyledCard>
  );
};

export default WhatMessenger;
