import { LinkHome, LinkVoice, LinkContact } from "route";
import { Layout as BaseLayout } from "antd";
import React from "react";
import styled from "styled-components";
import logo from "images/logo.png";

const StyledContent = styled(BaseLayout.Content)`
  flex: 1;
  display: flex;
  flex-flow: column;
`;

const StyledHeader = styled(BaseLayout.Header)`
  background: #ffffff;
  box-shadow: 0px 1px 5px #00000044;
  padding: 0;
  z-index: 999;
`;

const LogoImage = styled.img`
  height: 50px;
`;

const LinkSpan = styled.span`
  margin: 0 0.5em;
  color: #2f8895;
  font-family: "Lato-Bold";
  :hover {
    color: #71c2cb;
  }
`;

const SLink: React.FC<{ l: React.FC }> = (props) => (
  <props.l>
    <LinkSpan>{props.children}</LinkSpan>
  </props.l>
);

const Layout = (props: { router: React.FC }) => (
  <BaseLayout>
    <StyledHeader>
      <SLink l={LinkHome}>
        <LogoImage src={logo} />
      </SLink>
      <SLink l={LinkVoice}>Voice</SLink>
      <SLink l={LinkContact}>Contact</SLink>
    </StyledHeader>
    <StyledContent>
      <props.router />
    </StyledContent>
  </BaseLayout>
);

export default Layout;
