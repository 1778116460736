import { Card } from "antd";
import styled from "styled-components";

const StyledCard = styled(Card)`
  width: 50%;
  margin: 5em auto;
`;

const Contact = () => (
  <StyledCard title="Contact Us">
    Simply reach out to us at{" "}
    <a
      href="mailto:support@neblar.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      support@neblar.com
    </a>
  </StyledCard>
);

export default Contact;
