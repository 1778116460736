import { Card } from "antd";
import {
  DownloadOutlined,
  FileTextOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { openExternal, LinkWhatMessenger } from "route";
import ImgBllion from "images/legacy/Bllion.jpg";
import ImgClipSearch from "images/legacy/ClipSearch.jpg";
import ImgContactsHandler from "images/legacy/ContactsHandler.jpg";
import ImgDealDaddy from "images/legacy/DealDaddy.jpg";
import ImgDealOnline from "images/legacy/DealOnline.jpg";
import ImgGeekValley from "images/legacy/GeekValley.jpg";
import ImgITTechos from "images/legacy/ITTechos.jpg";
import ImgInfobys from "images/legacy/Infobys.jpg";
import ImgIstriwala from "images/legacy/Istriwala.jpg";
import ImgItfi from "images/legacy/Itfi.jpg";
import ImgMermaidDartsClub from "images/legacy/MermaidDartsClub.jpg";
import ImgPastrieShopPoster from "images/legacy/PastrieShopPoster.jpg";
import ImgPokerCard from "images/legacy/PokerCard.jpg";
import ImgPreksh from "images/legacy/Preksh.jpg";
import ImgRetailEurope from "images/legacy/RetailEurope.jpg";
import ImgSamarthJewellery from "images/legacy/SamarthJewellery.jpg";
import ImgSamarthJewelleryAdCampaign from "images/legacy/SamarthJewelleryAdCampaign.jpg";
import ImgScholarCritic from "images/legacy/ScholarCritic.jpg";
import ImgVDrive from "images/legacy/VDrive.jpg";
import ImgWhatMessenger from "images/legacy/WhatMessenger.jpg";

const work = () => [
  {
    title: "Automation",
    listing: [
      {
        coverImage: ImgWhatMessenger,
        title: "WhatMessenger",
        description: "Automate sending whatsapp messages",
        actions: [
          <LinkWhatMessenger>
            <div>
              <FileTextOutlined />
              Documentation
            </div>
          </LinkWhatMessenger>,
          <div
            onClick={() =>
              openExternal(
                "https://chrome.google.com/webstore/detail/whatmessenger/imhefehgikkfflhkbncoebpjacgnhbhm?hl=en"
              )
            }
          >
            <DownloadOutlined />
            (Obsolete) Webstore
          </div>,
        ],
      },
      {
        coverImage: ImgContactsHandler,
        title: "Contacts Handler",
        description: "Bulk contact management utility",
        actions: [
          <div
            onClick={() =>
              openExternal(
                "https://play.google.com/store/apps/details?id=com.neblar.contactshandler"
              )
            }
          >
            <DownloadOutlined />
            Playstore
          </div>,
        ],
      },
    ],
  },
  {
    title: "Graphic Design",
    listing: [
      {
        coverImage: ImgGeekValley,
        title: "Geek Valley",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgBllion,
        title: "Bllion",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgIstriwala,
        title: "Istriwala.in",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgRetailEurope,
        title: "Retail Europe",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgPreksh,
        title: "Preksh.com",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgDealOnline,
        title: "Deal Online",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgMermaidDartsClub,
        title: "Mermaid Darts Club",
        description: "Logo Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgPastrieShopPoster,
        title: "Délices d'Annette",
        description: "Poster Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgInfobys,
        title: "Infobys",
        description: "Logo Design / Stationary Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgItfi,
        title: "ITFI",
        description: "Logo Design / Stationary Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgITTechos,
        title: "iT techos",
        description: "Stationary Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgDealDaddy,
        title: "Deal Daddy",
        description: "App Design / Branding",
        actions: [],
      },
      {
        coverImage: ImgPokerCard,
        title: "PokerCard",
        description: "Graphic Design",
        actions: [],
      },
    ],
  },
  {
    title: "Mobile Apps",
    listing: [
      {
        coverImage: ImgVDrive,
        title: "VDrive",
        description: "Unleash your mobile's storage with virtual drives",
        actions: [
          <div
            onClick={() =>
              openExternal(
                "https://play.google.com/store/apps/details?id=com.neblar.android.vdrive"
              )
            }
          >
            <DownloadOutlined />
            Playstore
          </div>,
        ],
      },
      {
        coverImage: ImgClipSearch,
        title: "Clip Search",
        description: "Search based on text you copied in the past",
        actions: [
          <div
            onClick={() =>
              openExternal(
                "https://play.google.com/store/apps/details?id=com.neblar.android.clipsearch"
              )
            }
          >
            <DownloadOutlined />
            Playstore
          </div>,
        ],
      },
    ],
  },
  {
    title: "Websites",
    listing: [
      {
        coverImage: ImgSamarthJewellery,
        title: "Samarth Jewellery",
        description: "Website Design / Website Development",
        actions: [
          <div onClick={() => openExternal("http://samarthjewellery.com/")}>
            <ArrowRightOutlined />
            Visit Site
          </div>,
        ],
      },
      {
        coverImage: ImgSamarthJewelleryAdCampaign,
        title: "Samarth Jewellery Ad Campaign",
        description: "Website Design / Website Development",
        actions: [
          <div
            onClick={() =>
              openExternal("http://www.samarthjewellery.com/promo")
            }
          >
            <ArrowRightOutlined />
            (Expired) Link
          </div>,
        ],
      },
      {
        coverImage: ImgScholarCritic,
        title: "Scholar Critic",
        description: "Website Design / Website Development",
        actions: [
          <div onClick={() => openExternal("http://www.scholarcritic.com")}>
            <ArrowRightOutlined />
            (Expired) Link
          </div>,
        ],
      },
    ],
  },
];

const Title = styled.div`
  font-size: 1.5em;
  font-family: Lato-Light;
`;
const OuterCard = styled(Card)`
  width: 90%;
  background: none;
  margin: 2em auto;
  > .ant-card-body {
    display: block;
    text-align: center;
  }
`;
const ListingCard = styled(Card)`
  display: inline-block;
  width: 20%;
  min-width: 350px;
  text-align: left;
  margin: 1em;
  vertical-align: top;
`;

const Legacy = () => (
  <OuterCard title={<Title>Past Projects</Title>}>
    {work().map(({ title: categoryTitle, listing }) =>
      listing.map(({ coverImage, title, description, actions }) => (
        <ListingCard
          key={`${categoryTitle}.${title}`}
          hoverable={true}
          cover={<img src={coverImage} alt={`${categoryTitle}: ${title}`} />}
          actions={actions}
        >
          <Card.Meta
            title={`${title} (${categoryTitle})`}
            description={description}
          />
        </ListingCard>
      ))
    )}
  </OuterCard>
);

export default Legacy;
