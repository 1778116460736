const doc = `
# WhatMessenger **(Obsolete)**

## [Support Us](https://donorbox.org/support-whatmessenger)

This project has been revived because our users wanted us to keep the extension working with the latest versions of WhatsApp, you can support us by making a donation

## Request Features

We also welcome feature requests, and you can be an active part of the project by giving us valuable feedback and request for feature by sending us an email directly at [support@neblar.com](mailto:support@neblar.com)

## How it works

WhatMessenger works just like you do, it searches for the contacts, types the messages and then clicks on the send button to send messages to your whatsapp contacts automatically. It does the exact same thing for deleting the conversations, by searching for the contacts, then clicking on the delete conversation button.

--- 

## How to use WhatMessenger

Let us find out how we can automate sending WhatsApp messages over the web.whatsapp interface using WhatMessenger. There are two ways we can do this, one is sending messages to contacts who are named in patterns so that will be contacts like conPattern1, conPattern2, ... and the other way is to send messages to predefined list of contacts, for ex. contact name 1, another name 2, third name, me too...

We have broken this instruction down into the following sections:

1. [Setting the message to be sent](#setting-the-message-to-be-sent)
2. [Setting the list of contacts](#settings-the-list-of-contacts)
3. [Sending messages](#sending-messages)
4. [Deleting conversations](#deleting-conversations)
5. [Extra tricks](#extra-tricks)

Remember to use any of the features you have to use the extension on web.whatsapp's page only.

## Setting the message to be sent

Follow the following procedure to set the message that you want to send.

1. Open the extension
2. Go to Settings by clicking "SETTINGS"
3. Click on "Message"
4. Enter the message you want
5. Click on ACCEPT to save the settings

## Setting the list of contacts

There are multiple ways to select the contacts you want to send the messages to,

1. [Select from your existing list of WhatsApp contacts](#selecting-from-existing-list-of-contacts)
2. [General contact names, for ex. Super user, normal user, favorite client, favorite person, facebook friend, ...](#selecting-absolute-names)
3. [Contacts named in a pattern, for ex. name1, name2, name3, name4, ...](#selecting-names-in-a-pattern)

The same method is used to select the contacts for sending messages as well as to delete conversations.

Please note that if you have two exactly same contacts in your whatsapp account the extension will end up sending the message to the one that comes up first in search which may or may not be the same person twice.

### Selecting from existing list of contacts

You can follow this method to choose contacts you want from your existing list of WhatsApp contacts.

1. Open the extension
2. Go to Settings by clicking "SETTINGS"
3. Click on "Send Settings"
4. Select "Contacts"
5. Click on "Select from Contacts"
6. Select as many or as few contacts you want

#### Tips and Tricks

##### Selecting all but a few contacts

1. Click on "Select All" at the top in contact selection screen
2. Click on the contacts you want to deselect

##### Notice

If you have contacts with the exact same name, the extension will not be able to differentiate between them and the actions (sending/deleting messages) will take place in accordance with WhatsApp's search ranking i.e. the contact that comes up first in the search will be used for the actions.

### Selecting absolute names

You can follow this method to select any type of contacts such as people, groups etc.

1. Open the extension
2. Go to Settings by clicking "SETTINGS"
3. Click on "Send Settings"
4. Select "Absolute"
5. Enter the list of contacts you want to send messages to as names separated by commas. For ex,
  > The rabbit, the lion, uni corns, amazing spider, dave the minion, despicable you

### Selecting names in a pattern

You can follow this method to select contacts if you have the contacts in your whatsapp account who you want to send messages to saved as (name1, name2, name3, ...) Note that there are names and immediately after that without a space is a number. If you have names saved as this you can follow this method, otherwise follow the method to [set absolute contacts](#selecting-absolute-names)

1. Open the extension
2. Go to Settings by clicking "SETTINGS"
3. Click on "Send Settings"
4. Select "Pattern"
5. Select the pattern that you have set for your contacts. So if you have your contacts saved as   
  > patCon1, patCorn2, patCorn3, ...
  you have to enter "patCorn"
6. Select the starting and ending index of contacts you want to send the messages to, so if you have contacts starting from 1 to 100, you can set "Start index" as 1 and "End index" as 100 to send messages to all of them,

  or if you want to send messages to only contacts from 50 to 60, you can set the "Start index" to 50 and the "End index" to 60.

  If however you want to send messages to specific contacts like contactNo1, contactNo56, contactNo99, contactNo41,... then please [set the names as absolute contact](#selecting-absolute-names)

## Sending messages

Once you have finished [setting the contacts](#setting-the-list-of-contacts) as well as [setting your message](#setting-the-message-to-be-sent),
you can follow the following steps to send messages to the selected contacts on whatsapp automatically:

1. Open the extension
2. Click on SEND

## Deleting conversations

Follow the method to set contacts as mentioned in [setting the contacts](#setting-the-list-of-contacts) but instead of choosing "Send Settings"
to set the contacts, choose "Delete Settings" and then you can follow the following steps to delete whatsapp conversations automatically:

1. Open the extension
2. Click on DELETE

## Extra tricks

In additional to the normal features the extension sports the following extra tricks

### Sending same message to same user(s) multiple times

After you have [set your desired message](#setting-the-message-to-be-sent), you can [set the names of the user(s)](#selecting-absolute-names) you want to send the message to multiple times by entering them as many times you want to send them the message. These names have to be
set as absolute contacts.

So for ex if you want to send the same message to your friend 'anony mous' 5 times, you will [set the absolute contacts](#selecting-absolute-names) as follows:

> anony mous, anony mous, anony mous, anony mous, anony mous

`;

export default doc;