import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import IRoute from "./IRoute";

const getSuitableRoutes = (
  testRoutes: IRoute[],
  basePath: string
): { routes: IRoute[]; level: number } => {
  let routes = testRoutes;
  const pathGraph = basePath.split("/").filter((a) => a.length > 0);
  const level = pathGraph.length;
  pathGraph.forEach((f) => {
    routes =
      routes.find((tr) => tr.path.replace(/^\/+/g, "") === f)?.routes || [];
  });
  return { routes, level };
};

const AutoRouter = (routeConfig: IRoute[]) => {
  const { path: basePath } = useRouteMatch();
  const { routes } = getSuitableRoutes(routeConfig, basePath);
  const routeNodes = routes.map(({ path, Component }: any) => {
    return (
      <Route key={path} path={`${basePath}${path}`.replace("//", "/")}>
        <Component />
      </Route>
    );
  });
  return <Switch>{routeNodes}</Switch>;
};

const AutoRouterComponent = (routeConfig: IRoute[]) => () => {
  return AutoRouter(routeConfig);
};

export default AutoRouterComponent;
