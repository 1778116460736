import { LinkVoice, LinkLegacy } from "route";
import { Card } from "antd";
import { Logo } from "components";
import styled from "styled-components";
import back from "images/banners/back.svg";
import waves from "images/banners/waves.svg";
import legacy from "images/banners/legacy.png";

const StyledBack = styled.div`
  background-image: url(${back});
  background-size: 100%, contain;
  background-repeat: no-repeat;
  padding-bottom: 10em;
`;

const PositionedLogo = styled(Logo)`
  margin: 10em 2em;
  font-size: calc(1.5vw + 5px);
`;

const StyledCard = styled(Card)`
  width: 90%;
  margin: 2em auto;
`;

const VoiceCover = styled.div`
  background-image: url(${waves});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 10em;
`;

const LegacyCover = styled.div`
  background-image: url(${legacy});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  height: 10em;
`;

const Home = () => (
  <StyledBack>
    <PositionedLogo />
    <LinkVoice>
      <StyledCard hoverable cover={<VoiceCover />}>
        <Card.Meta
          title="Voice"
          description="Explore the world of downloadable voices"
        />
      </StyledCard>
    </LinkVoice>
    <LinkLegacy>
      <StyledCard hoverable cover={<LegacyCover />}>
        <Card.Meta title="Legacy Work" description="Browse our past projects" />
      </StyledCard>
    </LinkLegacy>
  </StyledBack>
);

export default Home;
