import styled from "styled-components";

const Title = styled.div`
  display: inline-block;
  text-align: justify;
  .t {
    display: block;
    font-size: 4em;
    line-height: 1em;
    font-family: "Lato-Light";
  }
  .s {
    display: block;
    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.65em;
    margin-left: 0.25em;
    color: #2f8892;
  }
`;

const Logo = (props: { className?: string }) => (
  <Title className={props.className}>
    <span className="t">NEBLAR</span>
    <span className="s">TECHNOLOGIES</span>
  </Title>
);

export default Logo;
