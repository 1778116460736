import AutoRouter from "./router";
import routes from "./routes";

const Router = AutoRouter(routes);

export const openExternal = (link: string) => {
  window.open(link, "_blank")?.focus();
};

export { Router };
export * from "./link";
export * from "./history";
export * from "./links";
